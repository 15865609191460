import React, { useState } from "react"
import { ChordType } from '@tonaljs/tonal';

import IntervalNote from '../components/IntervalNote';
import Layout from "../components/layout"
import SEO from "../components/seo"
import './chords.css';

const FlatSymbol = ({ double }) => 
  <span className="flat-symbol">{double === true ? '♭♭' : '♭'}</span>;

const IndexPage = () => {
  // default to major I guess?
  const [selected, selectChord] = useState(ChordType.get('major'));

  console.log('selected', selected);
  // explain Abbreviations like smart-keys? show chord types...etc
  const printChord = chord => console.log(chord.intervals, chord.aliases);
  const chords = [
    "major",
    "m",
    "maj7",
    "m7",
    "7",
    "dim",
    "dim7",
    "half-diminished",
    "9",
    "m9",
    "maj9",
    "maj13",
    "m13",
    // "11",
    // "13",
    "sus2",
    "sus4",
  ];

  // console.log(ChordType.names());

  return (
    <Layout>
      <SEO title="The Chords" />
      <p>
        This interactive chart highlights pitches that are common between 
        different chords. Pitches are relative to the major (Ionian) scale. 
        Consider a simple C minor chord: the relevant pitches are 
        C, E<FlatSymbol /> and G. Try clicking on table below to see 
        how common pitches change between different chords.
      </p>
      <div className="chords-gallery">
        {chords.map(c => ChordType.get(c)
        ).map(c =>
            <div className="chord-row" onClick={() => selectChord(c)}>
            { false && <div>{c.aliases.map(alias => <div>{alias} </div>)}</div>
        }
      <div className={c.name === selected.name ? "selected" : ""}>
        {c.name.replace('major', 'maj')
        .replace('minor','min')
        .replace('diminished','dim')}</div>
      {c.intervals.map((interval, i, hey = () => console.log(interval)) => 
        <IntervalNote 
          highlight={selected !== undefined 
            && interval === selected.intervals[i]
          }
          interval={interval} 
        />)
      }
        </div>)
        }
      </div>

      <div className="chord-chart-legend">
        <div>
          <code><FlatSymbol /></code> - indicates the flatted version of (e.g. half-step down)
        </div>
        <div>
          <code><FlatSymbol double={true}/></code> - indicates double-flatted version (e.g. whole-step down)
        </div>
        <div>
          <code>#</code> - indicates the sharped version of (e.g. half-step up)
        </div>
      </div>

    </Layout>
  );
}

export default IndexPage
