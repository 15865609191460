import React from 'react';

import './IntervalNote.css';

function renderInterval(interval) {
  switch (interval) {
    case '1P': return '1';
    case '3M': return '3';
    case '3m': return 'b3';
    case '5P': return '5';
    case '5d': return 'b5';
    case '7M': return '7';
    case '7m': return 'b7';
    case '7d': return 'bb7';
    case '9M': return '9';
    case '13M': return '13';
    case '11P': return '11';
    case '2M': return '2';
    case '4P': return '4';
    default: return 'd:' + interval;
  }
}

const IntervalNote = ({ interval, highlight }) => {
  console.log('i', interval, highlight);
  return (
    <div className={
    `interval-note ${highlight ? 'highlight' : ''}`
      }>
      {renderInterval(interval).replace('bb', '♭♭').replace('b','♭')}
    </div>
  );
}

export default IntervalNote;
